let twistMyParmColors = {
  primary: '#45EEFF',
  secondary: '#7833DE',
  accent: '#FFDD00',
  tertiary: '#2B38BD',
  error: '#FF5A47',
}

let shellaGoodColors = {
  // Background
  primary: '#FF5947',
  // Text
  secondary: '#FFDD00',
  // Logo
  accent: '#45EEFF',
  // Footer
  tertiary: '#2B38BD',
  error: '#8821EB',
}
let moverShakerColors = {
  primary: '#6027B4',
  secondary: '#00C9FF',
  accent: '#FFACB9',
  tertiary: '#FF5400',
  error: '#FF5A47',
}
let cheddyMacColors = {
  primary: '#00E0BE',
  secondary: '#007300',
  accent: '#FF2815',
  tertiary: '#FFDD00',
  error: '#FF5A47',
}

let downTheHatchColors = {
  // Green
  primary: '#007300',
  // Blue
  secondary: '#87EFF0',
  accent: '#FFACB9',
  tertiary: '#FF2815',
  error: '#FF5A47',
}

let defaultProductColors = {
  primary: '#C3F5D3',
  secondary: '#6E5BE8',
  accent: '#FFC8D0',
  tertiary: '#FF5400',
  error: '#FF5A47',
}
// TODO -- Refactor these to actually have descriptive titles
let pcp = {
  primary: '#F752AD',
  secondary: '#FFDD00',
  accent: '#FFC8D0',
  tertiary: '#FFC8D0',
  error: '#FF5A47',
}
let ope = {
  primary: '#B2FF28',
  secondary: '#FFDD00',
  accent: '#FFC8D0',
  tertiary: '#8821EB',
  error: '#FF5A47',
}
let cnf = {
  primary: '#C3F5D3',
  secondary: '#2B38BD',
  accent: '#FFB699',
  secondTxt: '#FF5600',
  tertiary: '#FFDD00',
  error: '#FF5A47',
}

let veganIsBelievin = {
  primary: '#71FF00',
  tertiary: '#45efff',
  accent: '#6027b4',
  secondary: '#007300',
}

let blogpink = {
  primary: '#F752AD',
  secondary: '#FFB699',
  accent: '#FFDD00',
  error: '#FF5A47',
}

let bloggreen = {
  primary: '#007300',
  secondary: '#C3F5D3',
  accent: '#FFDD00',
  error: '#FF5A47',
}

let blogblue = {
  primary: '#6E5BE8',
  secondary: '#CBC2FF',
  accent: '#FFDD00',
  error: '#FF5A47',
}

let cartPalette = {
  primary: '#FF5A47',
  secondary: '#2B38BD',
  accent: '#FFDD00',
}

let primaryButton = {
  backgroundColor: 'tertiary',
  borderRadius: '8px',
  color: 'secondary',
  cursor: 'pointer',
  fontFamily: 'heading',
  width: 200,
  fontSize: '1.25rem',
  lineHeight: 1,
  minHeight: '50px',
  padding: '0.875rem 1.125rem',
  whiteSpace: 'nowrap',
  transition: 'all 0.1s ease',
  '&:hover': {
    backgroundColor: 'secondary',
    color: 'tertiary',
  },
  '&:disabled': {
    cursor: 'default',
  },
}

export default {
  config: {
    // we're using color modes to easily swap color between products, not as a customizable experience,
    // so we'll turn localStorage off to prevent colors loading/falling-back on the wrong page
    useLocalStorage: false,
  },
  useLocalStorage: false,

  space: [0, 4, 8, 16, 30, 64, 128, 256, 512],
  // temporary breakpoint, can be changed if needed
  breakpoints: ['768px', '1024px'],
  fonts: {
    body: 'Sofia-Regular, system-ui, sans-serif', //change to sofia regular
    heading: 'Sofia, system-ui, sans-serif',
    monospace: 'Pitch, monospace',
    // primary: 'Roboto, system-ui, sans-serif',
    secondary: 'Marby, system-ui, sans-serif',
  },
  fontSizes: [
    12, // 0
    14, // 1
    16, // 2
    20, // 3
    24, // 4
    36, // 5
    48, // 6
    64, // 7
    96, // 8
    100, // 9
  ],
  fontWeights: {
    body: 400,
    bold: 600,
    extraBold: 700,
    heading: 900,
  },
  lineHeights: {
    body: 1.5,
    heading: 1,
  },
  borderWidths: {
    borderWidth: 2,
  },
  colors: {
    // Adding colors needed to start styling, but this can be refactored if needed
    // Color naming: https://chir.ag/projects/name-that-color/

    japaneseLaurel: '#007300', // Kind of green
    brightBlue: '#87EFF0',
    waxFlower: '#FFB699',
    blazeOrange: '#FF5600',
    supernova: '#F9C600',
    brilliantRose: '#F752AD',
    grape: '#6E5BE8',
    frenchPass: '#AEE0FD',
    robinsEggBlue: '#00E0BE',
    governorBay: '#2B38BD',
    schoolBusYellow: '#FFDD00',
    mayaBlue: '#67C7ED',
    blue: '#2B38BD',
    yellow: '#FFDD00',
    pink: '#FFC8D0',
    lightPink: '#FFACB9',
    sunsetOrange: '#FF5A47',
    red: '#FF2815',
    goodlesRed: '#FF2815',
    electricViolet: '#8821EB',
    azureRadiance: '#008BFA',
    modes: {
      Cart: {
        ...cartPalette,
        background: '#F7EFD9',
        override: {
          logo: {
            logoFront: cartPalette.accent,
            logoBack: cartPalette.secondary,
            altLogoFront: cartPalette.accent,
            altLogoBack: cartPalette.secondary,
          },

          footerBackground: shellaGoodColors.secondary,
          footerColor: shellaGoodColors.primary,
        },
      },
      Home: {
        // Components scoped outside of main page component seem to follow Cheddy Mac scheme
        ...cheddyMacColors,
        background: shellaGoodColors.primary,
        override: {
          logo: {
            logoFront: '#FFDD00',
            logoBack: cheddyMacColors.accent,
            altLogoFront: cheddyMacColors.primary,
            altLogoBack: cheddyMacColors.secondary,
          },
          detailMain: cheddyMacColors.accent,
          bowlOutline: cheddyMacColors.tertiary,
          stickyATCBorder: cheddyMacColors.tertiary,
          stickATCColor: cheddyMacColors.secondary,
          altButtonBackground: cheddyMacColors.tertiary,
          altButtonColor: '#2B38BD',
          footerColor: cheddyMacColors.accent,
          footerBackground: cheddyMacColors.tertiary,
          sectionBackground: cheddyMacColors.secondary,
        },
      },
      PCP: {
        ...pcp,
        background: pcp.primary,
        mac: {
          primary: '#2B38BD',
          secondary: '#FFDD00',
        },
        override: {
          logo: {
            logoFront: '#FFDD00',
            logoBack: '#6E5BE8',
            altLogoFront: pcp.primary,
            altLogoBack: pcp.secondary,
          },
          detailMain: pcp.accent,
          bowlOutline: pcp.tertiary,
          stickyATCBorder: pcp.tertiary,
          stickATCColor: pcp.secondary,
          altButtonBackground: pcp.tertiary,
          altButtonColor: '#2B38BD',
          footerColor: pcp.tertiary,
          footerBackground: pcp.primary,
          sectionBackground: pcp.secondary,
        },
      },
      CDP: {
        'mac-and-cheese': 'governorBay',
        goods: 'electricViolet',
        'variety-packs': 'japaneseLaurel',
        gifts: 'azureRadiance',
        defaultBackground: 'brilliantRose',
        defaultText: 'schoolBusYellow',
        transparent: 'transparent',
      },
      'Our-Story': {
        primary: '#87efd3',
        secondary: '#8821EB',
        background: '#87EFD3',
        override: {
          logo: {
            logoFront: '#45EEFF',
            logoBack: '#8821EB',
            altLogoFront: '#87EFD3',
            altLogoBack: '#FF5A47',
          },
          footerColor: '#45EEFF',
          footerBackground: '#8821EB',
        },
      },
      'Gift-Flow': {
        primary: '#2B38BD',
        secondary: '#007300',
        tertiary: '#FFDE00',
        background: '#00E0BE',
        lightPurple: '#AEE0FD',
        middleBackground: '#007300',
        override: {
          logo: {
            logoFront: '#FFDE00',
            logoBack: '#FF2815',
            altLogoFront: '#00E0BE',
            altLogoBack: '#007300',
          },
          footerColor: '#FF2815',
          footerBackground: '#FFDE00',
        },
      },
      'Gift-Flow-Form': {
        primary: '#2B38BD',
        secondary: '#AEE0FD',
        tertiary: '#CBC2FF',
        background: '#6E5BE8',
        error: '#FFB699',
        blue: '#2B38BD',
        yellow: '#FFDE00',
        override: {
          logo: {
            logoFront: '#FFB699',
            logoBack: '#FF5A47',
            altLogoFront: '#AEE0FD',
            altLogoBack: '#6E5BE8',
          },
          footerColor: '#FFB699',
          footerBackground: '#FF5A47',
        },
      },
      'Contact-Us': {
        primary: '#6e5be8',
        secondary: '#AEE0FD',
        background: '#6E5BE8',
        override: {
          logo: {
            logoFront: '#FFB699',
            logoBack: '#FF5A47',
            altLogoFront: '#AEE0FD',
            altLogoBack: '#6E5BE8',
          },
          footerColor: '#FFB699',
          footerBackground: '#FF5A47',
        },
      },
      FAQ: {
        primary: '#2b38bd',
        secondary: '#AEE0FD',
        background: '#2B38BD',
        override: {
          logo: {
            logoFront: '#FFB699',
            logoBack: '#FF5A47',
            altLogoFront: '#AEE0FD',
            altLogoBack: '#2B38BD',
          },
          footerColor: '#FFB699',
          footerBackground: '#FF5A47',
        },
      },
      'Us-Vs-Them': {
        primary: '#6e5be8',
        secondary: '#AEE0FD',
        background: '#6E5BE8',
        override: {
          logo: {
            logoFront: '#FFDD00',
            logoBack: '#2B38BD',
            altLogoFront: '#AEE0FD',
            altLogoBack: '#6E5BE8',
          },
          footerColor: '#FFB699',
          footerBackground: '#FF5A47',
        },
      },
      OPE: {
        ...ope,
        background: ope.primary,
        mac: {
          primary: '#2B38BD',
          secondary: '#FFDD00',
        },
        override: {
          logo: {
            logoFront: '#FFDD00',
            logoBack: '#6E5BE8',
            altLogoFront: ope.primary,
            altLogoBack: pcp.secondary,
          },
          detailMain: ope.accent,
          bowlOutline: ope.tertiary,
          stickyATCBorder: ope.tertiary,
          stickATCColor: ope.secondary,
          altButtonBackground: ope.tertiary,
          altButtonColor: '#2B38BD',
          footerColor: ope.tertiary,
          footerBackground: ope.primary,
          sectionBackground: ope.secondary,
        },
      },
      CNF: {
        ...cnf,

        background: cnf.primary,
        text: cnf.secondary,
        scndBg: cnf.accent,
        scndTxt: cnf.secondTxt,
        tertiary: cnf.tertiary,
        override: {
          logo: {
            logoFront: '#FFB699',
            logoBack: '#FF5600',
            altLogoFront: '#C3F5D3',
            altLogoBack: '#2B38BD',
          },
          footerBackground: cnf.accent,
          footerColor: cnf.secondTxt,
        },
      },
      'Default-Product': {
        ...defaultProductColors,
        background: defaultProductColors.primary,
        override: {
          logo: {
            logoFront: defaultProductColors.accent,
            logoBack: defaultProductColors.tertiary,
            altLogoFront: defaultProductColors.secondary,
            altLogoBack: defaultProductColors.primary,
          },
          detailMain: defaultProductColors.tertiary,
          bowlOutline: defaultProductColors.accent,
          stickyATCBorder: defaultProductColors.tertiary,
          stickyATCColor: defaultProductColors.tertiary,
          stickyATCBg: defaultProductColors.accent,
          altButtonBackground: defaultProductColors.tertiary,
          altButtonColor: '#FFDD00',
          footerColor: defaultProductColors.tertiary,
          footerBackground: defaultProductColors.accent,
          sectionBackground: defaultProductColors.secondary,
          waitlistBg: defaultProductColors.accent,
          waitlistColor: defaultProductColors.tertiary,
        },
      },
      'Twist-My-Parm': {
        ...twistMyParmColors,
        background: twistMyParmColors.primary,
        override: {
          logo: {
            logoFront: '#FFDD00',
            logoBack: twistMyParmColors.tertiary,
            altLogoFront: twistMyParmColors.primary,
            altLogoBack: twistMyParmColors.tertiary,
          },
          detailMain: twistMyParmColors.tertiary,
          bowlOutline: twistMyParmColors.accent,
          stickyATCBorder: twistMyParmColors.secondary,
          stickyATCColor: twistMyParmColors.secondary,
          stickyATCBg: twistMyParmColors.accent,
          altButtonBackground: twistMyParmColors.primary,
          altButtonColor: '#2B38BD',
          footerColor: twistMyParmColors.accent,
          footerBackground: twistMyParmColors.secondary,
          sectionBackground: twistMyParmColors.tertiary,
          waitlistBg: twistMyParmColors.accent,
          waitlistColor: twistMyParmColors.tertiary,
          ref: null,
        },
      },
      'Shella-Good': {
        ...shellaGoodColors,
        background: shellaGoodColors.primary,
        override: {
          logo: {
            logoFront: shellaGoodColors.accent,
            logoBack: shellaGoodColors.tertiary,
            altLogoFront: shellaGoodColors.secondary,
            altLogoBack: shellaGoodColors.primary,
          },
          detailMain: shellaGoodColors.tertiary,
          bowlOutline: shellaGoodColors.accent, //TODO
          stickyATCBorder: shellaGoodColors.tertiary,
          stickyATCColor: shellaGoodColors.tertiary,
          stickyATCBg: shellaGoodColors.accent,
          altButtonBackground: shellaGoodColors.tertiary,
          altButtonColor: '#FFDD00',
          footerColor: shellaGoodColors.secondary,
          footerBackground: shellaGoodColors.tertiary,
          sectionBackground: shellaGoodColors.secondary,
          waitlistBg: shellaGoodColors.accent,
          waitlistColor: shellaGoodColors.tertiary,
          ref: null,
        },
      },
      'Down-The-Hatch': {
        ...downTheHatchColors,
        background: downTheHatchColors.primary,
        override: {
          logo: {
            logoFront: downTheHatchColors.accent,
            logoBack: downTheHatchColors.tertiary,
            altLogoFront: downTheHatchColors.primary,
            altLogoBack: downTheHatchColors.secondary,
          },
          detailMain: downTheHatchColors.tertiary,
          bowlOutline: downTheHatchColors.tertiary,
          stickyATCBorder: cheddyMacColors.accent,
          stickyATCColor: cheddyMacColors.accent,
          stickyATCBg: '#FFDD00',
          altButtonBackground: downTheHatchColors.tertiary,
          altButtonColor: '#2B38BD',
          footerColor: downTheHatchColors.accent,
          footerBackground: downTheHatchColors.tertiary,
          sectionBackground: downTheHatchColors.secondary,
          waitlistBg: downTheHatchColors.tertiary,
          waitlistColor: downTheHatchColors.accent,
          ref: null,
        },
      },
      'Cheddy-Mac': {
        ...cheddyMacColors,
        background: cheddyMacColors.primary,
        override: {
          logo: {
            logoFront: '#FFDD00',
            logoBack: cheddyMacColors.accent,
            altLogoFront: cheddyMacColors.primary,
            altLogoBack: cheddyMacColors.secondary,
          },
          detailMain: cheddyMacColors.accent,
          bowlOutline: cheddyMacColors.tertiary,
          stickyATCBorder: cheddyMacColors.accent,
          stickyATCColor: cheddyMacColors.accent,
          stickyATCBg: '#FFDD00',
          altButtonBackground: cheddyMacColors.tertiary,
          altButtonColor: '#2B38BD',
          footerColor: cheddyMacColors.accent,
          footerBackground: cheddyMacColors.tertiary,
          sectionBackground: cheddyMacColors.secondary,

          waitlistBg: cheddyMacColors.tertiary,
          waitlistColor: cheddyMacColors.accent,
          ref: null,
        },
      },
      'Mover-Shaker': {
        ...moverShakerColors,
        background: moverShakerColors.primary,
        override: {
          logo: {
            logoFront: moverShakerColors.accent,
            logoBack: moverShakerColors.tertiary,
            altLogoFront: moverShakerColors.secondary,
            altLogoBack: moverShakerColors.primary,
          },
          detailMain: moverShakerColors.tertiary,
          bowlOutline: moverShakerColors.accent,
          stickyATCBorder: moverShakerColors.tertiary,
          stickyATCColor: moverShakerColors.tertiary,
          stickyATCBg: moverShakerColors.accent,
          altButtonBackground: moverShakerColors.tertiary,
          altButtonColor: '#FFDD00',
          footerColor: moverShakerColors.tertiary,
          footerBackground: moverShakerColors.accent,
          sectionBackground: moverShakerColors.secondary,
          waitlistBg: moverShakerColors.accent,
          waitlistColor: moverShakerColors.primary,
          ref: null,
        },
      },
      'Vegan-Believin': {
        ...veganIsBelievin,
        background: veganIsBelievin.primary,
        override: {
          logo: {
            logoFront: veganIsBelievin.tertiary,
            logoBack: veganIsBelievin.accent,
            altLogoFront: veganIsBelievin.primary,
            altLogoBack: veganIsBelievin.secondary,
          },
          detailMain: veganIsBelievin.accent,
          bowlOutline: veganIsBelievin.tertiary,
          stickyATCBorder: veganIsBelievin.accent,
          stickyATCColor: veganIsBelievin.accent,
          stickyATCBg: veganIsBelievin.tertiary,
          altButtonBackground: veganIsBelievin.tertiary,
          altButtonColor: '#2B38BD',
          footerColor: veganIsBelievin.accent,
          footerBackground: veganIsBelievin.tertiary,
          sectionBackground: veganIsBelievin.secondary,
          waitlistBg: veganIsBelievin.primary,
          waitlistColor: veganIsBelievin.accent,
          ref: null,
        },
      },
      'Blog-Pink': {
        ...blogpink,
        background: blogpink.primary,
        text: blogpink.secondary,
        override: {
          footerBackground: blogblue.secondary,
          footerColor: blogblue.primary,
          logo: {
            logoFront: '#CBC2FF',
            logoBack: '#6E5BE8',
            altLogoFront: blogpink.secondary,
            altLogoBack: blogpink.primary,
          },
        },
      },
      'Blog-Green': {
        ...bloggreen,
        background: bloggreen.primary,
        text: bloggreen.secondary,
        override: {
          footerBackground: blogpink.secondary,
          footerColor: blogpink.primary,
          logo: {
            logoFront: blogpink.secondary,
            logoBack: blogpink.primary,
            altLogoFront: blogblue.secondary,
            altLogoBack: blogblue.primary,
          },
        },
      },
      'Blog-Blue': {
        ...blogblue,
        background: blogblue.primary,
        text: blogblue.secondary,
        override: {
          footerBackground: blogpink.secondary,
          footerColor: blogpink.primary,
          logo: {
            logoFront: blogpink.secondary,
            logoBack: blogpink.primary,
            altLogoFront: blogblue.secondary,
            altLogoBack: blogblue.primary,
          },
        },
      },
    },
  },
  styles: {
    root: {
      variant: 'text.body',
      a: { color: 'inherit', textDecoration: 'none' },
    },
  },
  forms: {
    input: {
      color: 'currentColor',
      borderColor: 'currentColor',
      '&:focus': {
        color: 'currentColor',

        borderColor: 'currentColor',
        boxShadow: '0 0 0 2px',
        boxShadowColor: 'currentColor',
        outline: 'none',
      },
      minicart: {
        variant: 'input',
        background: 'white',
        color: '#FF5A47',
        borderColor: '#FF5A47',
        '&:focus': {
          borderColor: '#FF5A47',
          boxShadow: 'inset 0 0 0 2px',
          boxShadowColor: '#FF5A47',
          outline: 'none',
        },
      },
      gift: {
        backgroundColor: 'white',
        borderRadius: 8,
        height: 48,
        padding: '10px 20px',
        border: 'unset',
      },
    },
    radio: {
      'input ~ &': {
        border: '1px solid',
        borderColor: 'override.detailMain',
        color: 'transparent',
      },
      'input:checked ~ &': {
        border: ' 5px solid',
        borderColor: 'override.detailMain',
        backgroundColor: 'white',
        color: 'white',
      },
    },
    select: {
      // height: ['2.5rem', '3.125rem'],
      width: 'inherit',
      borderRadius: '8px',
      borderWidth: 2,
      background: 'white',
      color: 'tertiary',
      fontWeight: 'bold',
      borderColor: 'tertiary',
      fontSize: 16,
      paddingTop: 11,
      paddingBottom: 10,
      paddingLeft: 20,
    },
    checkbox: {
      color: 'white',
      backgroundColor: 'unset !important',
    },
  },
  buttons: {
    primary: {
      ...primaryButton,
    },
    gift: {
      primary: {
        ...primaryButton,
        backgroundColor: 'tertiary',
        color: 'primary',
        width: 'fit-content',
        padding: '0px 30px',
        ':hover': {
          color: 'tertiary',
          backgroundColor: 'primary',
        },
      },
      secondary: {
        ...primaryButton,
        backgroundColor: 'primary',
        color: 'tertiary',
        width: 'fit-content',
        padding: '0px 30px',
        ':hover': {
          backgroundColor: 'tertiary',
          color: 'primary',
        },
      },
      form: {
        ...primaryButton,
        backgroundColor: '#2B38BD',
        color: '#FFDE00',
        width: 'fit-content',
        padding: '0px 30px',
        ':hover': {
          backgroundColor: '#FFDE00',
          color: '#2B38BD',
        },

        inverted: {
          ...primaryButton,
          color: '#2B38BD',
          backgroundColor: '#FFDE00',
          width: 'fit-content',
          padding: '0px 30px',

          ':hover': {
            backgroundColor: '#2B38BD',
            color: '#FFDE00',
          },
        },
      },
    },
    invert: {
      ...primaryButton,
      backgroundColor: 'secondary',
      color: 'tertiary',
      ':hover': {
        backgroundColor: 'tertiary',
        color: 'secondary',
      },
    },
    subscription: {
      ...primaryButton,
      color: 'secondary',
      maxWidth: '138px',
      height: '44px',
      lineHeight: '44px',
      verticalAlign: 'middle',
    },

    radio: {
      boxSizing: 'border-box',
      variant: 'buttons.primary',
      bg: 'white',
      color: 'override.detailMain',
      borderColor: 'override.detailMain',
      boxShadow: '0 0 0 0px',
      boxShadowColor: 'override.detailMain',
      borderRadius: '8px',
      borderStyle: 'solid',
      textTransform: 'capitalize',
    },
    radio_active: {
      variant: 'buttons.radio',
      color: 'override.detailMain',
      boxShadow: '0 0 0 2px',
      WebkitTextStroke: '1.2px',
      WebkitTextStrokeColor: 'override.detailMain',
      bg: 'white',
    },
    link: {
      color: 'secondary',
      backgroundColor: 'transparent',
      fontFamily: 'heading',
      textDecoration: 'underline',
      cursor: 'pointer',
      fontSize: 3,
    },
    productCard: {
      alignItems: 'center',
      borderRadius: '8px',
      color: 'var(--theme-ui-colors-mac-secondary)',
      justifyContent: 'center',
      display: 'flex',
      fontSize: ['18px', '20px'],
      width: '70%',
      height: '50px',
      margin: '20px auto',
      fontFamily: 'Sofia',
      background: 'var(--theme-ui-colors-mac-primary)',
      '&:hover': {
        background: '#AEE0FD',
      },
    },
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted',
    },
    testimonials: {
      'Green & Yellow': {
        backgroundColor: 'japaneseLaurel',
        color: 'schoolBusYellow',
      },
      'Orange & Pink': {
        backgroundColor: 'sunsetOrange',
        color: 'pink',
      },
      'Blue & Blue': {
        backgroundColor: 'governorBay',
        color: 'frenchPass',
      },
    },
  },
  layout: {
    container: {
      margin: 'auto',
      // maxWidth: 'calc(1600px + 8rem)',
      padding: ['0 1rem', '0 1rem', '0 4rem'],
      // overflow: 'hidden',
      width: '100%',
    },
    tablet: {
      margin: 'auto',
      maxWidth: 'calc(1600px)',
      padding: [0, null, null],
      // overflow: 'hidden',
      width: '100%',
    },
    fullWidth: {
      padding: [0, null, null],
      // overflow: 'hidden',
      width: '100%',
    },
  },
  responsive: {
    mobile: {
      display: ['block', 'none', 'none'],
    },
    mobileTablet: {
      display: ['block', 'block', 'none'],
    },
    tablet: {
      display: ['none', 'block', 'none'],
    },
    desktop: {
      display: ['none', 'none', 'block'],
    },
    tabletDesktop: {
      display: ['none', 'block', 'block'],
    },
  },
  text: {
    body: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: 2,
      large: {
        fontFamily: 'body',
        lineHeight: '24px',
        fontWeight: 'body',
        fontSize: '20px',
      },
    },

    small: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    caps: {
      textTransform: 'uppercase',
    },
    formInput: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    formLabel: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      textTransform: 'uppercase',
    },
    formError: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      textTransform: 'uppercase',
      color: 'errorDark',
    },
    link: {
      color: 'inherit',
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      textDecoration: 'none',
      letterSpacing: '2px',
      textTransform: 'uppercase',
    },
    subscription: {
      fontSize: '19px',
      fontWeight: 900,
      color: 'secondary',
      fontFamily: 'heading',
      lineHeight: '24px',
    },
    h1: {
      color: 'inherit',
      fontFamily: 'heading',
      lineHeight: ['heading', '80px'],
      fontWeight: 'heading',
      fontSize: ['3rem', '100px'],
      textTransform: 'uppercase',
    },
    h2: {
      color: 'inherit',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: ['40px', '60px'],
      textTransform: 'uppercase',
    },
    h3: {
      color: 'inherit',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: ['32px', '48px'],
      textTransform: 'uppercase',
    },
    h4: {
      color: 'inherit',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: ['32px', '36px'],
      textTransform: 'uppercase',
    },
    h5: {
      color: 'inherit',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: ['22px', '24px'],
      textTransform: 'uppercase',
    },
    eyebrow: {
      fontFamily: 'Marby',
      fontSize: '14px',
      lineHeight: 1,
      uppercase: {
        fontFamily: 'Marby',
        fontSize: '14px',
        lineHeight: 1,
        textTransform: 'uppercase',
        letterSpacing: '.5px',
      },
    },
  },
}
