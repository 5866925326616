import './static/fonts/fonts.css'
import './src/styles/animations.css'
import React from 'react'
import GorgiasChat from '~/components/Layout/GorgiasChat'
import ConsentManagerWrapper from '~/components/Segment/ConsentManager'
import { logSentryError } from '@chordcommerce/gatsby-theme-autonomy'
import { localeFromPathname } from '~/utils/intl/localePrefix'
import { storage } from '@chordcommerce/gatsby-theme-autonomy'
import { MessageProvider } from '~/contexts/MessageContext'
import TikTokEventProvider from '~/components/TikTokHelper'

const originalStorageMethods = {
  getItem: storage.getItem,
  setItem: storage.setItem,
  removeItem: storage.removeItem,
}

for (const method in originalStorageMethods) {
  const originalMethod = originalStorageMethods[method]
  storage[method] = (...args) => {
    const key = `${localeFromPathname().code}|${args.shift()}`
    try {
      return originalMethod(...[key, ...args])
    } catch (error) {
      console.error(error)
    }
  }
}

export const wrapPageElement = ({ element, ...restProps }, ...args) => {
  return (
    <ConsentManagerWrapper>
      <TikTokEventProvider>
        <MessageProvider>{element}</MessageProvider>
      </TikTokEventProvider>
      <GorgiasChat></GorgiasChat>
    </ConsentManagerWrapper>
  )
}

export const onClientEntry = () => {
  if (typeof window === 'undefined') {
    return
  }

  try {
    const { analytics, location } = window
    const params = new URLSearchParams(location.search)
    const getCookie = name => {
      return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        return parts[0] === name ? decodeURIComponent(parts[1]) : r
      }, null)
    }
    const ttclid = params.get('ttclid') || getCookie('ttclid')
    if (params.get('ttclid')) {
      document.cookie = `ttclid=${ttclid}`
    }

    analytics.addSourceMiddleware(({ payload, next }) => {
      if (payload && payload.obj) {
        const locale = localeFromPathname(location && location.pathname)
        const extraPayload = {
          locale: locale.code,
        }
        const oosElement = document.querySelector(
          '[class *= "ProductOutOfStock"]',
        )
        if (oosElement) {
          extraPayload['from_waitlist_modal'] = true
        }
        payload.obj.properties = {
          ...(payload.obj.properties || {}),
          ...extraPayload,
        }
        payload.obj.traits = { ...(payload.obj.traits || {}), ...extraPayload }
      }

      try {
        if (ttclid) {
          payload.obj.properties.ttclid = ttclid
        }

        next(payload)
      } catch (error) {
        next(payload)
        logSentryError(error, { source: 'addSourceMiddleware' })
      }
    })
  } catch (error) {
    logSentryError(error, { source: 'addSourceMiddleware' })
  }
}
