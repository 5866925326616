import { useEffect, useState } from 'react'

const useMatchMedia = media => {
  const [matches, setMatches] = useState(typeof window !== 'undefined' ? media : null);

  const mediaListener = ({ matches }) => {
    setMatches(matches)
  }

  useEffect(() => {
    const mq = window.matchMedia(media)
    setMatches(mq.matches)

    mq.addEventListener
      ? mq.addEventListener('change', mediaListener)
      : mq.addListener(mediaListener)

    return () => {
      mq.removeEventListener
        ? mq.removeEventListener('change', mediaListener)
        : mq.removeListener(mediaListener)
    }
  }, [media])

  return matches
}

export default useMatchMedia
