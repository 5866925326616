const languages = ['fr-CA', 'en-CA'/*, 'he-IL'*/];

const locales = {
  'en-US': 'USA',
  // 'fr-CA': 'Canada – French',
  'en-CA': 'Canada – English',
  // 'he-IL': 'Israel'
}

const currencies = {
  'en-US': 'USD',
  'en-CA': 'CAD',
  'fr-CA': 'CAD'
}

const localePrefix = (pathname) => {
  const actualPathName =  pathname || (typeof window === 'undefined' ? '' : window.location.pathname);
  const path = `${actualPathName}`.split('/').filter(v => v).shift()
  return languages.indexOf(path) !== -1 ? `/${path}/` : '/'
}

const localeFromPathname = (pathname) => {
  const actualPathName =  pathname || (typeof window === 'undefined' ? '' : window.location.pathname);
  const path = `${actualPathName}`.split('/').filter(v => v).shift()
  const code = languages.find(v => v === path) || 'en-US';
  return {
    code,
    label: locales[code],
    currency: currencies[code] || 'USD'
  }
}

const toURL = (pathname, url) => {
  const actualUrl = url || pathname  // URL is the first argument if no second one is provided
  const actualPathName = url ? pathname : (typeof window === 'undefined' ? '' : window.location.pathname);
  const cleanedUrl = actualUrl.indexOf('/') === 0 ? actualUrl.substring(1) : actualUrl;

  return `${localePrefix(actualPathName)}${cleanedUrl}`
}
export default localePrefix
export {
  toURL,
  localePrefix,
  localeFromPathname,
  languages,
  currencies,
  locales
}