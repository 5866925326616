module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en-US","en-CA"],"defaultLanguage":"en-US","path":"/opt/buildhome/repo/src/intl"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"","environment":"production","enabled":"","denyUrls":[{},{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"5fMq8YYK3zKK09UJ928aY2CpL18FaoKV","devKey":"5fMq8YYK3zKK09UJ928aY2CpL18FaoKV","trackPage":true,"host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":true},
    },{
      plugin: require('../node_modules/@chordcommerce/gatsby-theme-autonomy/gatsby-browser.js'),
      options: {"plugins":[],"contentfulConfig":{"spaceId":"bdj7cfajz56z","accessToken":"B-fSKpomKQzTpNgUftY4FoN3YuTIwSEGGFhDr1h7U4A","host":"cdn.contentful.com","environment":"master"},"intlConfig":{"languages":["en-US","en-CA"],"defaultLanguage":"en-US","path":"/opt/buildhome/repo/src/intl"},"siteMetadata":{"title":"Goodles","siteUrl":"https://goodles.com","description":"Delicious Mac and Cheese With Protein and Nutrients","social":{"facebook":"allgoodles","instagram":"allgoodles","pinterest":"allgoodles"}},"authConfig":{"magic":{"apiKey":"pk_live_0CCABD52C458AD7F"}},"segmentConfig":{"prodKey":"5fMq8YYK3zKK09UJ928aY2CpL18FaoKV","devKey":"5fMq8YYK3zKK09UJ928aY2CpL18FaoKV","trackPage":true,"host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
